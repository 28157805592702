import { combineReducers } from 'redux';
import localStorage from 'redux-persist/lib/storage';
import homeReducer from '../../modules/home/slice/homeSlice';
import loginReducer from '../../modules/login/slice/loginSlice';
import fundReducer from '../../modules/fund/Slice/FundSlice';
import notification from 'modules/home/slice/notification';
import fundDataReducer from 'modules/fund/Slice/FundDataSlice';
import scoringMatrixReducer from 'modules/fund/Slice/ScoringMatrixSlice';
import portfolioReducer from 'modules/PacingPlan/slice/pacingPlanSlice';
import landingPageReducer from 'modules/landingPage/slice/landingPageSlice';
import fundManagerReducer from 'modules/fundManager/slice/fundManagerSlice';
import fundDocumentReducer from 'modules/fundDocument/slice/fundDocumentSlice';
import investmentReducer from 'modules/investments/slice/investmentSlice';
import contactsReducer from 'modules/contactPage/slice/contactsSlice';

const appReducer = combineReducers({
  home: homeReducer,
  login: loginReducer,
  fundDetails: fundReducer,
  fundData: fundDataReducer,
  notification: notification,
  scoringMatrix: scoringMatrixReducer,
  portfolio: portfolioReducer,
  landingPage: landingPageReducer,
  fundManagerPage: fundManagerReducer,
  fundDocumentPage: fundDocumentReducer,
  investmentPage: investmentReducer,
  contactsPage: contactsReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === 'login/logout') {
    // this applies to all keys defined in persistConfig(s)
    localStorage.removeItem('persist:root');
    state = {};
    window.location?.reload();
  }
  return appReducer(state, action);
};
