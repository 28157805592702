import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fundData: null,
  fundCompareTable: null,
  displayNames: false,
  fundFieldCol: [],
  isBlackBookCollapse: false,
  fundBenchmark: {},
  selecteFundCompare: [],
  fundReferences: [],
  usersList: [],
  companiesList: [],
  peoplesList: [],
  saveInfo: null,
};

const fundDataSlice = createSlice({
  name: 'fundData',
  initialState,
  reducers: {
    setFundData: (state, action) => {
      state.fundData = action.payload;
    },
    setFundBenchmark: (state, action) => {
      state.fundBenchmark = action.payload;
    },
    setFundCompareTable: (state, action) => {
      state.fundCompareTable = action.payload;
    },
    setDisplayNames: (state, action) => {
      state.displayNames = action.payload;
    },
    setFundFieldCol: (state, action) => {
      state.fundFieldCol = action.payload;
    },
    setIsBlackBookCollapse: (state, action) => {
      state.isBlackBookCollapse = action.payload;
    },
    setSelectedFundCompares: (state, action) => {
      state.selecteFundCompare = action.payload;
    },
    setDisplayConfigOrder: (state, action) => {
      state.displayUserConfig = action.payload;
    },
    setFundReferences: (state, action) => {
      state.fundReferences = action.payload;
    },
    setUsersList: (state, action) => {
      state.usersList = action.payload; // Reducer for usersList
    },
    setCompaniesList: (state, action) => {
      state.companiesList = action.payload; // Reducer for companiesList
    },
    setPeoplesList: (state, action) => {
      state.peoplesList = action.payload; // Reducer for companiesList
    },
    setSaveInfo: (state, action) => {
      state.saveInfo = action.payload; // Reducer for companiesList
    },
  },
});

export const {
  setSaveInfo,
  setFundData,
  setFundCompareTable,
  setDisplayNames,
  setFundFieldCol,
  setIsBlackBookCollapse,
  setSelectedFundCompares,
  setFundBenchmark,
  setDisplayConfigOrder,
  setFundReferences,
  setUsersList,
  setCompaniesList,
  setPeoplesList,
} = fundDataSlice.actions;

export default fundDataSlice.reducer;
