import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import IconButton from 'ui-controls/button/IconButton';
const InputBox = ({
  id = 'priButton',
  name = 'mainName',
  type = 'text',
  onBlur = () => {},
  onChange = () => {},
  isDisable,
  className = '',
  value = '',
  placeholder,
  label,
  infoTxt,
  isTextArea = '',
  isTextAreaFor = false,
  defaultFields = null,
  autocomplete = true,
  step = 0,
  min = 0,
  max = 0,
  ...rest
}) => {
  const { isLanguage } = useSelector((state) => state.login);
  const [height, setHeight] = useState('auto');
  const textareaRef = useRef(null);
  const inputRef = useRef(null);
  const cursorPosition = useRef(0);

  const handleCursorPosition = (e) => {
    cursorPosition.current = e.target.selectionStart;
  };

  useEffect(() => {
    if (textareaRef.current) {
      if (
        isTextArea ||
        (defaultFields && defaultFields !== 'recommendation_amount')
      ) {
        // Set height to 'auto' to calculate the scrollHeight accurately
        textareaRef.current.style.height = isTextAreaFor ? '150px' : 'auto';
        textareaRef.current.style.width = isTextAreaFor ? '100%' : '100%'; // Set height to scrollHeight to adjust height dynamically
        textareaRef.current.style.height = isTextAreaFor
          ? '250px'
          : `${textareaRef.current.scrollHeight}px`;
        setHeight(`${textareaRef.current.scrollHeight}px`);
        // textareaRef.current.focus();
        textareaRef.current.setSelectionRange(
          cursorPosition.current,
          cursorPosition.current
        );
      }
    }
    // else if (inputRef.current) {
    // inputRef.current.focus();
    // inputRef.current.setSelectionRange(
    //   cursorPosition.current,
    //   cursorPosition.current
    // );
    // }
  }, [value, isTextArea, defaultFields]);

  const inputClass = classNames(className, {
    inputStyle: true,
    'input-disabled': isDisable,
    'Txt-REV': isLanguage === 'ARB',
  });

  const lablStyle = classNames(className, {
    lblTxt: true,
    'lblTxt-REV': isLanguage === 'ARB',
  });

  const infoStyle = classNames(className, {
    infTxt: true,
    'lblTxt-REV': isLanguage === 'ARB',
  });

  return (
    <div>
      {label ? <div className={lablStyle}>{label}</div> : null}
      {isTextArea ||
      (defaultFields && defaultFields !== 'recommendation_amount') ? (
        <textarea
          ref={textareaRef}
          key={id}
          onBlur={onBlur}
          disabled={isDisable}
          onChange={(e) => {
            handleCursorPosition(e);
            onChange(e);
          }}
          className={inputClass}
          value={value}
          placeholder={placeholder}
          style={{ height: isTextAreaFor ? '100px' : height }}
          {...rest}
        />
      ) : (
        <input
          ref={inputRef}
          key={id}
          onBlur={onBlur}
          type={type ? type : 'text'}
          disabled={isDisable}
          onChange={(e) => {
            handleCursorPosition(e);
            onChange(e);
          }}
          className={inputClass}
          value={value}
          placeholder={placeholder}
          {...rest}
          step={step}
          min={min}
          max={max}
        />
      )}
      {infoTxt ? <div className={infoStyle}>{infoTxt}</div> : null}
    </div>
  );
};

export default InputBox;
