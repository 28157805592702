import fetchFromApiServer from 'services/api';

export function fetchDocuments(requestObject) {
  const url = `/funddocument-page/fund-document`;
  return fetchFromApiServer('GET', url, requestObject);
}

export function reprocessFundDocument(requestObject) {
  const url = `/funddocument-page/reprocess-document`;
  return fetchFromApiServer('POST', url, requestObject);
}
