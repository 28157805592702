import { fetchDocuments, reprocessFundDocument } from './fundDocumentActions';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Create async thunks for fetching data with error handling
export const getFundDocuments = createAsyncThunk(
  'GET_FUND_DOCUMENTS',
  async (params, { rejectWithValue }) => {
    try {
      const response = await fetchDocuments(params);
      if (response.status !== 200) {
        // Handle non-200 responses by returning a custom error payload
        return rejectWithValue({
          status: response.status,
          error: response.data?.error || 'Failed to fetch due diligence funds',
        });
      }

      return response.data; // Success, return the data
    } catch (error) {
      // Handle unexpected errors (e.g., network issues)
      return rejectWithValue({
        status: error.response?.status || 500,
        error:
          error.message ||
          'Unknown error occurred while fetching due diligence funds',
      });
    }
  }
);

// Create async thunks for reprocessing data with error handling
export const sendReprocessFundDocument = createAsyncThunk(
  'REPROCESS_FUND_DOCUMENTS',
  async (params, { rejectWithValue }) => {
    try {
      const response = await reprocessFundDocument(params);
      if (response.status !== 200) {
        // Handle non-200 responses by returning a custom error payload
        return rejectWithValue({
          status: response.status,
          error: response.data?.error || 'Failed to Reprocess Fund Documents',
        });
      }

      return response; // Success, return the data
    } catch (error) {
      // Handle unexpected errors (e.g., network issues)
      return rejectWithValue({
        status: error.response?.status || 500,
        error:
          error.message ||
          'Unknown error occurred while reprocessing fund documents',
      });
    }
  }
);

const initialState = {
  fundDocuments: [],
  fundDocumentColumns: [],
  totalRecords: 0,
};

export const fundDocumentPageSlice = createSlice({
  name: 'fundDocumentPage',
  initialState,
  reducers: {
    setFundDocuments: (state, action) => {
      state.fundDocuments = action.payload;
    },
    setTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
  },
});

// Export actions
export const { setFundDocuments, setFundDocumentColumns, setTotalRecords } =
  fundDocumentPageSlice.actions;

// Export the reducer
export default fundDocumentPageSlice.reducer;
