import React from 'react';
import PropTypes from 'prop-types';
import { MdEdit } from 'react-icons/md';
import { FaRegCircleCheck } from 'react-icons/fa6';
import { MdClose } from 'react-icons/md';

const IconButton = ({ type, onClick, isDisable = false }) => {
  let icon, text, className;

  switch (type) {
    case 'Edit':
      icon = <MdEdit />;
      text = 'Edit';
      className = 'editBtun mx-2 linkBtn';
      break;
    case 'Cancel':
      // icon = <FaTimes />;
      text = 'Cancel';
      className = 'cancelBtn mx-2 linkBtn';
      break;
    case 'Save':
      icon = <FaRegCircleCheck />;
      text = 'Save';
      className = 'saveNewBtn';
      break;
    case 'Close':
      icon = <MdClose />;
      text = 'Close';
      className = 'editBtun mx-2 linkBtn';
      break;
    default:
      icon = null;
      text = '';
      className = '';
  }

  return (
    <button
      className={`custom-btn ${className}`}
      onClick={onClick}
      disabled={isDisable}
    >
      {icon} <span className="btn-text">{text}</span>
    </button>
  );
};

IconButton.propTypes = {
  type: PropTypes.oneOf(['Edit', 'Cancel', 'Save']).isRequired,
  onClick: PropTypes.func.isRequired,
  isDisable: PropTypes.bool,
};

export default IconButton;
