import { fetchContacts } from './contactsActions';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Create async thunks for fetching data with error handling
export const getContacts = createAsyncThunk(
  'GET_CONTACTS',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchContacts();

      if (response.status !== 200) {
        // Handle non-200 responses by returning a custom error payload
        return rejectWithValue({
          status: response.status,
          error: response.data?.error || 'Failed to fetch due diligence funds',
        });
      }

      return response.data; // Success, return the data
    } catch (error) {
      // Handle unexpected errors (e.g., network issues)
      return rejectWithValue({
        status: error.response?.status || 500,
        error:
          error.message ||
          'Unknown error occurred while fetching due diligence funds',
      });
    }
  }
);

const initialState = {
  contactsData: [],
  contactsColumns: [],
};

export const contactsPageSlice = createSlice({
  name: 'contactsPage',
  initialState,
  reducers: {
    setContactacts: (state, action) => {
      state.contactsData = action.payload;
    },
    setContactsColumns: (state, action) => {
      state.contactsColumns = action.payload;
    },
  },
});

// Export actions
export const { setContactacts, setContactsColumns } = contactsPageSlice.actions;

// Export the reducer
export default contactsPageSlice.reducer;
