import { fetchInvestments } from './fundManagerActions';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Create async thunks for fetching data with error handling
export const getInvestments = createAsyncThunk(
  'GET_INVESTMENTS',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchInvestments();

      if (response.status !== 200) {
        // Handle non-200 responses by returning a custom error payload
        return rejectWithValue({
          status: response.status,
          error: response.data?.error || 'Failed to fetch due diligence funds',
        });
      }

      return response.data; // Success, return the data
    } catch (error) {
      // Handle unexpected errors (e.g., network issues)
      return rejectWithValue({
        status: error.response?.status || 500,
        error:
          error.message ||
          'Unknown error occurred while fetching due diligence funds',
      });
    }
  }
);

const initialState = {
  investmentsData: [],
  investmentsColumns: [],
};

export const investmentPageSlice = createSlice({
  name: 'investmentPage',
  initialState,
  reducers: {
    setInvestments: (state, action) => {
      state.investmentsData = action.payload;
    },
    setInvestmentColumns: (state, action) => {
      state.investmentsColumns = action.payload;
    },
  },
});

// Export actions
export const { setInvestments, setInvestmentColumns } =
  investmentPageSlice.actions;

// Export the reducer
export default investmentPageSlice.reducer;
