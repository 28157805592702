import React, { useEffect, useState } from 'react';
import Table from 'components/Grid';
import { useSelector, useDispatch } from 'react-redux';
import {
  getFundCompareData,
  getFundCompareFields,
} from 'modules/home/slice/homeSlice';
import { useLocation } from 'react-router-dom';
import { marked } from 'marked';

const FundCompareTable = ({ fundKey = '', SelectedOptions = [] }) => {
  const [columnList, setColumnList] = useState([]);
  const [visibleData, setVisibleData] = useState([]);
  const [fundField, setFundField] = useState([]);
  const { fundCompareTable } = useSelector(({ fundData }) => fundData);
  const [fundCompareData, setFundCompareData] = useState([]);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { selecteFundCompare } = useSelector((state) => state?.fundData);

  const sortArrayBasedOnOne = (firstArray, secondArray) => {
    // Create a map to store the index of each value in the first array
    const indexMap = new Map(firstArray.map((value, index) => [value, index]));

    let data = secondArray.sort((a, b) => indexMap.get(a) - indexMap.get(b));

    // Sort the second array based on the index in the first array
    return data;
  };

  useEffect(() => {
    const fundIds = fundCompareTable?.fund_compare_metadata_in_json?.fund_ids;
    const clonedFundIds = fundIds ? [...fundIds] : [];
    const sortedFundIdArray = clonedFundIds
      ?.sort((a, b) => a.displayOrder - b.displayOrder)
      ?.map((fund) => fund?.fund_id);
    let data;
    const fundIdsFromSelectedOptions = sortArrayBasedOnOne(
      sortedFundIdArray.length > 0
        ? sortedFundIdArray
        : [Number(fundCompareTable?.fund_id)],
      [
        Number(fundCompareTable?.fund_id ? fundCompareTable?.fund_id : fundKey),
        ...(SelectedOptions?.length > 0
          ? SelectedOptions.map((option) => option.value)
          : selecteFundCompare.map((option) => option.value)),
      ]
    );

    if (fundIdsFromSelectedOptions.length >= 0) {
      data = {
        fund_ids: fundIdsFromSelectedOptions,
      };
    } else {
      data = {
        fund_ids: sortedFundIdArray ? sortedFundIdArray : [Number(fundKey)],
      };
    }

    dispatch(getFundCompareData(data))
      .then((res) => {
        setFundCompareData(res?.payload?.data?.data);
      })
      .catch((err) => {
        console.error('Error fetching fund compare data:', err);
      });
  }, [
    fundCompareTable,
    fundKey,
    dispatch,
    SelectedOptions,
    selecteFundCompare,
  ]);

  useEffect(() => {
    if (!fundField.length) {
      dispatch(
        getFundCompareFields({
          fund_id: fundKey ? fundKey : fundCompareTable?.fund_id,
        })
      ).then((raw) => {
        if (raw?.payload?.status === 200) {
          setFundField(raw?.payload?.data?.data);
        }
      });
    }
  }, [dispatch, fundKey, fundCompareTable?.fund_id, fundField.length]);

  const MarkdownCellRenderer = (params) => {
    const { value } = params;
    const htmlContent = marked(value || '');
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };

  const getValueByKey = (sectionData, key) => {
    const [base_key, sub_key] = key?.split('.');

    let baseItems = [];

    for (const item of sectionData) {
      const itemsArray = item[base_key] || [];
      baseItems = baseItems.concat(itemsArray);
    }
    // Find the first item with the sub_key
    const itemWithSubKey = baseItems.find(
      (item) => item[sub_key] !== undefined
    );
    const data = itemWithSubKey ? itemWithSubKey[sub_key] : undefined;
    if (base_key === 'fund-fees') {
      const data = baseItems.filter((item) => item.fee_type === sub_key);
      const datas = data.map((res) => {
        return `${res?.fee_value || ''}`.trim();
      });
      const joinedValues = datas.join('\n');
      return joinedValues;
    }

    if (data === null || data === 'null' || data === 'N/A') {
      return '';
    }
    return data;
  };

  useEffect(() => {
    if (fundCompareData && fundCompareTable) {
      const rawData = fundCompareData.map((raw) => {
        const dynamicFields =
          fundCompareTable?.fund_compare_metadata_in_json?.column_data
            ?.filter((colData) => colData?.field !== 'Name')
            ?.map((colData) => {
              const displayName = fundField?.find(
                (field) => field?.displayName === colData?.field
              )?.key;

              if (displayName) {
                return {
                  [colData?.field]: getValueByKey(raw?.sections, displayName),
                };
              }
              return null;
            })
            ?.filter((item) => item !== null);

        return {
          fundId: raw?.fund_id,
          Name: getValueByKey(raw?.sections, 'fund-details.name'),
          'Inception Year': getValueByKey(
            raw?.sections,
            'fund-details.inception_year'
          ),
          Size: getValueByKey(raw?.sections, 'fund-details.size'),
          'Max Size': getValueByKey(
            raw?.sections,
            'fund-details.max_fund_size'
          ),
          'Investment Period': getValueByKey(
            raw?.sections,
            'fund-terms.investment_period'
          ),
          Duration: getValueByKey(raw?.sections, 'fund-terms.fund_duration'),
          Extension: getValueByKey(raw?.sections, 'fund-terms.extension'),
          'Management Fee': getValueByKey(
            raw?.sections,
            'fund-fees.Management Fee'
          ),
          'Carried Interest': getValueByKey(
            raw?.sections,
            'fund-terms.carried_interest'
          ),
          'Preferred Return': getValueByKey(
            raw?.sections,
            'fund-terms.preferred_return'
          ),
          'GP Commitment': getValueByKey(
            raw?.sections,
            'fund-terms.gp_commitment'
          ),
          ...dynamicFields?.reduce((acc, item) => ({ ...acc, ...item }), {}),
        };
      });

      if (!rawData || rawData.length === 0) {
        // If rawData is empty, set an empty data state
        setVisibleData([]);
        setColumnList([]);
        return;
      }

      // Transpose the raw data
      const transposedData = Object.keys(rawData[0] || {}).map((key) => ({
        field: key,
        ...rawData.reduce((acc, item, index) => {
          acc[`Fund ${index + 1}`] = item[key];
          return acc;
        }, {}),
      }));

      if (!transposedData || transposedData.length === 0) {
        setVisibleData([]);
        setColumnList([]);
        return;
      }

      // Use the first row as headers and exclude it from the data
      const nameRow = transposedData.find((row) => row.field === 'Name');
      const filteredData = transposedData.filter(
        (row) => row.field !== 'fundId' && row.field !== 'Name'
      );

      setVisibleData(filteredData);

      // Generate column list dynamically based on the first row
      const transposedColumns = Object.keys(nameRow || {}).map((key) => ({
        Header: nameRow[key], // Use values from the 'Name' row as headers
        accessor: key,
        id: key,
      }));

      setColumnList(transposedColumns);
    }
  }, [
    fundCompareData,
    fundCompareTable?.fund_compare_metadata_in_json?.column_data,
    fundField,
  ]);

  return (
    <Table
      allowAdd
      allowExport
      onAdd={alert}
      data={visibleData}
      isLoading={false}
      columns={columnList}
      customSorting={false}
      customPagination={false}
      addButtonName="Add User"
      sectionName="BlackBookFundCompare"
      // scrollStyle="scrollable-fund scroll fix-height"
      ContainerClass={
        pathname == '/black-book'
          ? 'table-container-fund'
          : 'table-container-fund blackbook-new scrollable'
      }
    />
  );
};

export default FundCompareTable;
